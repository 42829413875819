export default [
  {
    path: '/jogos',
    name: 'jogos',
    component: () => import('@/views/jogos/Jogos.vue'),
  },
  {
    path: '/jogos/:gameId/SelecionarPaciente',
    name: 'jogoSelecionaPaciente',
    props: true,
    component: () => import('@/views/jogos/SelecionarPacienteJogo.vue'),
  },
  {
    path: '/jogos/:gameId/:patientId/:medicalAppointmentId/Sessao',
    name: 'jogoSessao',
    props: true,
    component: () => import('@/views/jogos/Sessao.vue'),
  },
]
