export default [
  {
    path: '/pacientes',
    name: 'pacientes',
    component: () => import('@/views/paciente/paciente-list/PacienteList.vue'),
  },
  {
    path: '/adicionarPaciente',
    name: 'adicionarPaciente',
    component: () => import('@/views/paciente/paciente-form/PacienteForm.vue'),
  },
  {
    path: '/paciente',
    name: 'paciente',
    component: () => import('@/views/paciente/paciente/Paciente.vue'),
  },
  {
    path: '/paciente/:pacienteId',
    name: 'pacienteDetail',
    component: () => import('@/views/paciente/paciente/Paciente.vue'),
    props: true,
  },
  {
    path: '/pacientes/:id',
    name: 'pacienteEdit',
    component: () => import('@/views/paciente/paciente-form/PacienteForm.vue'),
    props: true,
  },
]
