export default [
  {
    path: '/profissionais',
    name: 'profissionais',
    component: () => import('@/views/admin/profissionais_lista.vue'),
  },
  {
    path: '/instituicoes',
    name: 'instituicoes',
    component: () => import('@/views/admin/instituicoes_lista.vue'),
  },

  {
    path: '/add_instituicao',
    name: 'add_instituicao',
    component: () => import('@/views/admin/add_instituicao.vue'),
  },

  {
    path: '/novoProfissional',
    name: 'profissionalAdd',
    component: () => import('@/views/admin/add_profissional.vue'),
  },

  {
    path: '/profissional/:id',
    name: 'profissionalEdit',
    component: () => import('@/views/admin/add_profissional.vue'),
    props: true,
  },
]
