export default [
  {
    path: '/novaConsulta',
    name: 'novaConsulta',
    component: () => import('@/views/consultas/nova-consulta/NovaConsulta.vue'),
  },
  {
    path: '/reagendarConsulta/:idConsulta',
    name: 'reagendarConsulta',
    props: true,
    component: () => import('@/views/consultas/nova-consulta/NovaConsulta.vue'),
  },
  {
    path: '/pesquisarConsulta',
    name: 'pesquisarConsulta',
    component: () => import('@/views/consultas/pesquisar-consulta/PesquisarConsulta.vue'),
  },

  {
    path: '/NovaConsultaInicial/:consultaId',
    name: 'NovaConsultaInicial',
    component: () => import('@/views/consultas/tipos-consulta/NovaConsultaInicial.vue'),
    props: true,
  },

  {
    path: '/NovoTesteCognitivo/:idConsulta',
    name: 'NovoTesteCognitivo',
    component: () => import('@/views/consultas/tipos-consulta/NovoTesteCognitivo.vue'),
    props: true,
  },
  {
    path: '/NovoJogoTeste/:idConsulta',
    name: 'NovoJogoTeste',
    component: () => import('@/views/consultas/tipos-consulta/NovoJogoTeste.vue'),
    props: true,
  },

  {
    path: '/NovaAplicacaodeJogo/:idConsulta',
    name: 'NovaAplicacaodeJogo',
    component: () => import('@/views/consultas/tipos-consulta/NovaAplicacaodeJogo.vue'),
  },

  
  // {
  //   path: '/consulta',
  //   name: 'singleConsultaExample',
  //   component: () => import('@/views/consultas/single-consulta/singleConsulta.vue'),
  // },
  {
    path: '/consulta/:idConsulta',
    name: 'detalheConsulta',
    component: () => import('@/views/consultas/single-consulta/singleConsulta.vue'),
    props: true,
  },

  {
    path: '/resultado/:idConsulta',
    name: 'resultado',
    props: true,
    component: () => import('@/views/consultas/single-consulta/ResultadoConsulta.vue'),
  },
  
]


