import * as moment from 'moment'

moment.locale('pt-br')

export default {
  getConsultations(id) {
    const consultation = { consultationTypeId: id }
    if (id.toString() == '4') consultation['gameId'] = 1
    else if (id.toString() == '3') consultation['gameId'] = 3
    return consultation
  },
  getQueryParams(params) {
    // eslint-disable-next-line prefer-template
    return Object.keys(params)
      .map(key => key + '=' + params[key])
      .join('&')
  },
  getByLetter(letter) {
    switch (letter) {
      case 'M':
        return 'Masculino'
      case 'F':
        return 'Feminino'
      default:
        return 'N/A'
    }
  },
  getClassColorPill(type) {
    switch (type) {
      case 'Aplicação do Jogo':
        return 'pill-testecog'
      case 'Jogo Teste':
        return 'pill-jogoteste'
      case 'Consulta Inicial':
        return 'pill-cons-ini'
      case 'Consulta Final':
        return 'pill-cons-fin'
      case 'Testes Cognitivos':
        return 'pill-testcog'
      default:
        return type
    }
  },
  getAgeByBirthday(birthday) {
    return moment().diff(birthday, 'years', false)
  },
  getFromNowByDate(date) {
    return moment(date).fromNow()
  },
  getExtenseDate(date) {
    return moment(date).fromNow()
  },
  getExtenseDateExact(date) {
    return moment(date).format('LL')
  },
  getHourFromDate(date) {
    return moment(date).format('LT')
  },
  getAbreviate(firstName, lastName) {
    return (
      firstName?.charAt(0).toUpperCase() + lastName?.charAt(0).toUpperCase()
    )
  },
  getFullNameByPatient(patient) {
    return `${patient.physicalPerson.firstName} ${patient.physicalPerson.lastName}`
  },
  getAbreviateByPatient(patient) {
    if (!patient) return ''
    return this.getAbreviate(
      patient.physicalPerson.firstName,
      patient.physicalPerson.lastName
    )
  },
  getClearedObject(obj) {
    return Object.keys(obj).reduce((acc, key) => {
      const acceptedsTypes = ['number', 'boolean']
      if (
        acceptedsTypes.some(type => type.includes(typeof obj[key])) ||
        !this.isNullOrEmpty(obj[key])
      ) {
        acc[key] = obj[key]
      }
      return acc
    }, {})
  },
  isNullOrEmpty(obj) {
    if (Array.isArray(obj)) {
      return obj.length === 0
    }
    if (typeof obj === 'object' && obj !== null) {
      return Object.keys(obj).every(value => this.isNullOrEmpty(obj[value]))
    }
    if (!obj) {
      return true
    }
  },
  getTitleRole(role) {
    if (role === 'rehabilitation_professional') return 'Profissional'
    return ''
  },
  downloadFile(data, filename, type) {
    var file = new Blob([data], { type: type })
    var fileURL = URL.createObjectURL(file)
    window.open(fileURL)
    return
    console.log(file)
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(file, filename) //IE 10+
    } else {
      var a = document.createElement('a')
      var url = window.URL.createObjectURL(file)
      a.href = url
      a.download = filename
      document.body.appendChild(a)
      a.click()
      setTimeout(function () {
        document.body.removeChild(a)
        window.URL.revokeObjectURL(url)
      }, 0)
    }
  },
}
